import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { GalleryImages } from 'app/components/GalleryImages'
import { Hero } from 'app/components/Hero'
import { Info } from 'app/components/Info'
import { Intro } from 'app/components/Intro'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { ZigZagVisual } from 'app/components/ZigZagVisual'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { MeetingForm } from 'app/containers/MeetingForm'
import { Props as SidebarProps, Sidebar } from 'app/containers/Sidebar'
import {
  MeetingFormSenderBackend,
  MeetingFormSenderBackendConfiguration,
} from 'app/utils/MeetingFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  sidebarProps?: SidebarProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
  meetingFormSenderBackendConfiguration: MeetingFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function CorporateEventsPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      MeetingFormSenderBackend(
        pageContext.meetingFormSenderBackendConfiguration,
      ),
    [pageContext.meetingFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.sidebarProps ? (
        <Sidebar
          languageCode={context.languageCode}
          {...context.sidebarProps}
        />
      ) : null}
      <GoogleAnalytics />
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? (
        <Intro variant="dark" {...context.introProps} />
      ) : null}
      {context.zigZagVisualProps ? (
        <ZigZagVisual variant="dark" {...context.zigZagVisualProps} />
      ) : null}
      {context.galleryImagesProps ? (
        <GalleryImages variant="dark" {...context.galleryImagesProps} />
      ) : null}
      {context.zigZagVisualSecondProps ? (
        <ZigZagVisual {...context.zigZagVisualSecondProps} />
      ) : null}
      {context.infoProps ? (
        <Info variant="dark" {...context.infoProps} />
      ) : null}
      <MeetingForm
        {...(context.meetingFormProps ? context.meetingFormProps : null)}
        onMeetingFormSendToBackend={async (data) =>
          await sendFormToBackend(data)
        }
      />
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
